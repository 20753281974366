svg, a, header, footer, section, div, span, img, p, h1, h2, h3, h4 {
/*  border: 1px solid red;*/
  margin: 0px;
  padding: 0px;
}

header {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  position: fixed;
  background: #FFFFFF;
  width: 100vw;
  top: 0px;
  left: 0px;
  z-index: 15;
  opacity: 0.95;
}

a {
  text-decoration: none;
  color: #13073E;
}

.app {
  padding: 100px 148px;
  display: grid;
  gap: 32px;
}

.image_logo {
  height: 36px;
  width: auto;
}

.header_left {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 12px;
  font-family: Roboto, Arial;
  font-size: 20px;
  letter-spacing: 2px;
  margin: 32px;
}

.image_phone_small {
  display: none;
}

.header_right {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 36px;
  font-family: Roboto, Arial;
  font-size: 20px;
  letter-spacing: 2px;
  margin: 32px;
}

.link_button_small {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  background-color: #F4C01E;
  padding: 8px 20px;
  border-radius: 50px;
}

.section1 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  background-image: linear-gradient(to right, #4549BE , #FF66C4);
  height: 75vh;
  border-radius: 20px;
  margin-bottom: 148px;
}

.section1_left {
  display: grid;
  font-family: Roboto, Arial;
  font-size: 20px;
  letter-spacing: 2px;
  padding: 64px;
  gap: 32px;
  color: #FFFFFF;
}

.section1_right {
  display: grid;
  justify-items: center;
  align-items: center;
}

.image_phone {
  height: 85vh;
  width: auto;
  position: absolute;
  margin-top: -4px;
}

h1 {
  font-family: Roboto, Arial;
  font-size: 36px;
  letter-spacing: 2px;
}

.width75 {
  width: 75%;
}

.footnote {
  font-size: 12px;
}

p {
  font-family: Roboto, Arial;
  font-size: 20px;
  letter-spacing: 1.5px;
}

.tag {
  background-color: #EFA9FA;
  padding: 4px 24px;
  border-radius: 50px;
  letter-spacing: 1px;
  width: fit-content;
  color: #13073E;
}

.link_button_large {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto, Arial;
  font-size: 20px;
  color: #13073E;
  letter-spacing: 1.5px;
  background-color: #FFFFFF;
  padding: 4px 24px;
  border-radius: 50px;
  margin-right: -16px;
}

.arrow {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  background-color: #13073E;
  color: #FFFFFF;
  border-radius: 50px;
  position: relative;
  right: -16px;
}

.feather-arrow-right_light {
  stroke: #FFFFFF;
  stroke-width: 2;
  fill: none;
}

.feather-arrow-right_dark {
  stroke: #13073E;
  stroke-width: 2;
  fill: none;
}

h2 {
  font-family: Roboto, Arial;
  font-size: 36px;
  letter-spacing: 2px;
/*  color: #13073E;*/
}

.section2_outer {
  display: grid;
  gap: 48px;
  margin-bottom: 148px;
}

.section2 {
  display: grid;
  grid-template-columns: 30% 30% 30%;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
}

/*.section3 {
  display: grid;
  grid-template-columns: 23% 23% 23% 23%;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
}*/

.card1 {
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 62px 48px;
  line-height: 32px;
  height: -webkit-fill-available;
  border-radius: 20px;
}

h3 {
  font-family: Roboto, Arial;
  font-size: 32px;
  letter-spacing: 2px;
}

.link_button_large.dark {
  display: flex;
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  font-family: Roboto, Arial;
  font-size: 20px;
  color: #FFFFFF;
  letter-spacing: 1.5px;
  background-color: #4549BE;
  padding: 4px 24px;
  border-radius: 50px;
  margin-right: -16px;
}

h4 {
  font-family: Roboto, Arial;
  font-size: 28px;
  letter-spacing: 2px;
}

.card2 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 48px;
  background-image: linear-gradient(140deg, #4549BE, #FF66C4);
  height: -webkit-fill-available;
  border-radius: 20px;
  color: #FFFFFF;
}

.plan {
  font-family: Roboto, Arial;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 2px;
  color: #FFFFFF;
}

.faux_bullet {
  display: grid;
  grid-template-columns: 5% 75%;
  align-items: center;
  gap: 30px;
}

.benefits-toggle_people {
  font-family: Roboto, Arial;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #13073E;
  background-color: #F4C01E;
  border-radius: 50px;
  border: 0px;
  padding: 8px 24px 12px 28px;
  cursor: pointer;
}

.benefits-toggle_retailers {
  font-family: Roboto, Arial;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 2px;
  color: #FFFFFF;
  background-color: #4549BE;
  border-radius: 50px;
  border: 0px;
  padding: 8px 24px 12px 28px;
  cursor: pointer;
}

.toggle {
  display: none;
}

.yellow {
  background-color: #F4C01E;
  color: #13073E;
}

.purple {
  background-color: #4549BE;
  color: #FFFFFF;
}

.modal_video {
  position: fixed;
  background: #000000;
  margin: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 3;
}

#video {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cursor_point:hover {
  cursor: pointer;
}

.image_phone_small.video_button {
  margin: 0;
  position: absolute;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 75px;
  height: auto;
}

.close_modal {
  background-color: #FFFFFF;
  border-style: solid;
  border-radius: 50px;
  padding: 4px 8px;
}