
@media only screen and (max-width: 1500px) {
  body {
/*    background-color: red;*/
  }
  .app {
    padding: 100px 48px;
    display: grid;
    gap: 36px;
  }
  header {
    grid-template-columns: 30% 70%;
  }
}

@media only screen and (max-width: 1024px) {
  body {
/*    background-color: yellow;*/
  }

  .footnote {
    font-size: 10px;
  }
  .section1_left, .card1, .card2 {
    padding: 36px;
  }
  .app {
    padding: 100px 32px;
    gap: 20px;
  }
  .header_right {
    display: none;
  }
  .image_phone {
    height: 65vh;
    width: auto;
    position: absolute;
    margin-top: -4px;
  }
  h1, h2, h3, h4, .benefits-toggle_people, .benefits-toggle_retailers {
    font-size: 28px;
  }
  .plan {
    font-size: 16px;
  }
  .section1, .section2, .section2_outer {
    column-gap: 0px;
    row-gap: 20px;
    margin-bottom: 0px;
    padding: 0px;
  }
  .section2 {
    display: flex;
    flex-direction: column;
    gap: 28px;
    padding: 0px;
    align-items: unset;
  }
  .faux_bullet {
    gap: 4px;
  }
}

@media only screen and (max-width: 800px) {
  body {
/*    background-color: pink;*/
  }
  .image_phone_small {
    display: unset;
    width: 300px;
    height: auto;
    place-self: center;
    justify-self: center;
  }
  .app {
    margin: 12px;
    padding: 32px 0px;
  }

  .header_left {
    margin: 12px;
  }

  .header_right {
    margin: 12px;
  }
  .card1 {
    padding: 32px 24px;
  }
  .section1_left {
    padding: 24px;
  }
  .section1 {
    grid-template-columns: 100% 0%;
    height: 100%;
  }
  .section1_right {
    display: none;
  }
  .card2 {
    margin: 0px;
    gap: 8px;
    padding: 24px;
  }
  .section2 {
    align-items: unset;
  }
  .hide {
    display: none;
  }
  .width75 {
    width: 100%;
  }
  .faux_bullet {
    grid-template-columns: 10% 90%;
  }
}




/* Safari 11+ */
@media not all and (max-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    body {
/*      background-color: red;*/
    }
    .image_phone {
      height: 75vh;
      width: auto;
      position: unset;
      margin-top: unset;
    }
    .card1, .card2 {
      height: unset;
    }
    .section2 {
      align-items: unset;
    }
  }
}